._webDevelopmentPage_ve90h_9 ._heroSectionGradient_ve90h_9 {
  background: linear-gradient(60deg,rgba(8,78,16,.76) 0%,rgba(65,100,78,.64) 24.68%,rgba(50,95,47,.56) 48.18%,rgba(10,14,15,.4) 72.22%,rgba(10,14,15,.24) 100%
  );
  max-height: 90rem;
}
._webDevelopmentPage_ve90h_9 ._heroSectionContentContainer_ve90h_13 {
  padding-top: 11.625rem;
  padding-bottom: 7.625rem;
}
@media only screen and (max-width: 1200px) {
  ._webDevelopmentPage_ve90h_9 ._heroSectionContentContainer_ve90h_13 {
    width: 100%;
    margin: 0px;
    padding: 10.25rem 1.5rem;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: 1rem;
  }
}
@media only screen and (max-width: 480px) {
  ._webDevelopmentPage_ve90h_9 ._heroSectionContentContainer_ve90h_13 {
    padding: 8.938rem 1rem 5.438rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
  }
}
._webDevelopmentPage_ve90h_9 ._howWeWorkSectionContainer_ve90h_35 {
  padding: 7rem 0px;
}
@media only screen and (max-width: 1200px) {
  ._webDevelopmentPage_ve90h_9 ._howWeWorkSectionContainer_ve90h_35 {
    padding: 4.5rem 1.5rem;
  }
}
@media only screen and (max-width: 480px) {
  ._webDevelopmentPage_ve90h_9 ._howWeWorkSectionContainer_ve90h_35 {
    padding: 4rem 1rem;
  }
}
