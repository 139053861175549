._contentFrame_7cjcw_9 {
    padding-top: 4rem;
    padding-bottom: 7rem
}

@media only screen and (max-width: 480px) {
    ._contentFrame_7cjcw_9 {
        padding-top:1.5rem;
        padding-bottom: 4.25rem
    }
}

._contentFrame_7cjcw_9 ._tabAndSearchRow_7cjcw_19 {
    grid-column: 1/13;
    border-bottom: 1px solid #eff2f7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    flex-wrap: wrap
}

@media only screen and (max-width: 1200px) {
    ._contentFrame_7cjcw_9 ._tabAndSearchRow_7cjcw_19 {
        grid-column:1/9
    }
}

@media only screen and (max-width: 480px) {
    ._contentFrame_7cjcw_9 ._tabAndSearchRow_7cjcw_19 {
        border-bottom:unset;
        grid-column: 1/5;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 1.5rem
    }
}

@media only screen and (max-width: 480px) {
    ._contentFrame_7cjcw_9 ._tabAndSearchRow_7cjcw_19 ._tab_7cjcw_19 {
        width:100%;
        border-bottom: 1px solid #eff2f7
    }
}

._contentFrame_7cjcw_9 ._tabAndSearchRow_7cjcw_19 ._search_7cjcw_49 ._searchInput_7cjcw_49 {
    width: 20.5rem
}

@media only screen and (max-width: 480px) {
    ._contentFrame_7cjcw_9 ._tabAndSearchRow_7cjcw_19 ._search_7cjcw_49,._contentFrame_7cjcw_9 ._tabAndSearchRow_7cjcw_19 ._search_7cjcw_49 ._searchInput_7cjcw_49 {
        width:100%
    }
}

._contentFrame_7cjcw_9 ._cardContainer_7cjcw_60 {
    margin-top: 3rem;
    grid-column: 1/13;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 16px
}

@media only screen and (max-width: 1200px) {
    ._contentFrame_7cjcw_9 ._cardContainer_7cjcw_60 {
        grid-column:1/9;
        grid-template-columns: repeat(2,1fr)
    }
}

@media only screen and (max-width: 480px) {
    ._contentFrame_7cjcw_9 ._cardContainer_7cjcw_60 {
        margin-top:2rem;
        grid-column: 1/5;
        grid-template-columns: 1fr
    }
}

._contentFrame_7cjcw_9 ._cardContainer_7cjcw_60 ._caseStudyCardContainer_7cjcw_80 {
    grid-column: span 1
}

@media only screen and (max-width: 1200px) {
    ._contentFrame_7cjcw_9 ._cardContainer_7cjcw_60 ._caseStudyCardContainer_7cjcw_80 {
        min-width:100%
    }
}

._contentFrame_7cjcw_9 ._emptyPage_7cjcw_88 {
    margin-top: 3rem
}

@media only screen and (max-width: 480px) {
    ._contentFrame_7cjcw_9 ._emptyPage_7cjcw_88 {
        margin-top:2rem
    }
}

._ourWork_84a5w_9 ._heroSectionGradient_84a5w_9 {
    background: linear-gradient(60deg,rgba(8,78,16,.76) 0%,rgba(65,100,78,.64) 24.68%,rgba(50,95,47,.56) 48.18%,rgba(10,14,15,.4) 72.22%,rgba(10,14,15,.24) 100%)
}

._ourWork_84a5w_9 ._heroSectionContentContainer_84a5w_12 {
    padding-top: 17.6875rem;
    padding-bottom: 13.6875rem
}

@media only screen and (max-width: 480px) {
    ._ourWork_84a5w_9 ._heroSectionContentContainer_84a5w_12 {
        padding-top:13.4375rem;
        padding-bottom: 13.4375rem
    }
}
