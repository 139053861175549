._blogsPage_ebbzq_9 ._heroSectionGradient_ebbzq_9 {
    background: linear-gradient(60deg,rgba(8,78,16,.76) 0%,rgba(65,100,78,.64) 24.68%,rgba(50,95,47,.56) 48.18%,rgba(10,14,15,.4) 72.22%,rgba(10,14,15,.24) 100%)
}


@media only screen and (max-width: 480px) {
    ._blogsPage_ebbzq_9 ._heroSectionGradient_ebbzq_9 {
        background: linear-gradient(60deg,rgba(8,78,16,.76) 0%,rgba(65,100,78,.64) 24.68%,rgba(50,95,47,.56) 48.18%,rgba(10,14,15,.4) 72.22%,rgba(10,14,15,.24) 100%)
    }
}

._blogsPage_ebbzq_9 ._heroSectionContentContainer_ebbzq_17 {
    padding-top: 15.75rem;
    padding-bottom: 15.75rem;
}

@media only screen and (max-width: 480px) {
    ._blogsPage_ebbzq_9 ._heroSectionContentContainer_ebbzq_17 {
        height: 36.375rem;
    }
}

._blogsPage_ebbzq_9 ._featuredBlogsWrapper_ebbzq_26 {
    background-color: rgb(249, 250, 253);
}

._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 {
    grid-column: span 12;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 4rem 0px;
}

@media only screen and (max-width: 1200px) {
    ._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 {
        grid-column: span 8;
        display: flex;
        align-items: flex-start;
        flex-direction: column-reverse;
        gap: 3rem;
    }
}

@media only screen and (max-width: 480px) {
    ._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 {
        border-bottom: unset;
        padding: 4rem 0rem;
        grid-column: span 4;
        display: flex;
        align-items: flex-start;
        flex-direction: column-reverse;
        gap: 3rem;
    }
}

._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 ._pillContainer_ebbzq_56 {
    width: 70%;
    grid-column: span 12;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem 0.5rem;
}

@media only screen and (max-width: 1200px) {
    ._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 ._pillContainer_ebbzq_56 {
        width: 100%;
        grid-column: span 8;
    }
}

@media only screen and (max-width: 480px) {
    ._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 ._pillContainer_ebbzq_56 {
        border-bottom: unset;
        grid-column: span 4;
        width: 100%;
    }
}

._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 ._pillContainer_ebbzq_56 ._viewMorePills_ebbzq_78 {
    display: none;
}

@media only screen and (max-width: 480px) {
    ._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 ._pillContainer_ebbzq_56 ._viewMorePills_ebbzq_78 {
        display: block;
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 145%;
        color: rgb(44, 116, 214);
    }
}

._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 ._pillContainer_ebbzq_56 ._clearBtn_ebbzq_92 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 145%;
    color: rgb(44, 116, 214);
}

._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 ._pillContainer_ebbzq_56 ._clearBtn_ebbzq_92:hover {
    cursor: pointer;
}

._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 ._pills_ebbzq_29 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 145%;
    color: rgb(91, 94, 97);
    letter-spacing: 0.031rem;
    padding: 0.37rem 0.62rem;
    border-radius: 1.25rem;
    cursor: pointer;
    border: 0.06rem solid rgb(233, 238, 245);
}

._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 ._activePill_ebbzq_116 {
    color: rgb(255, 255, 255);
    background-color: rgb(44, 116, 214);
}

._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 ._search_ebbzq_121 ._searchInput_ebbzq_121 {
    width: 20.5rem;
    height: auto;
}

@media only screen and (max-width: 1200px) {
    ._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 ._search_ebbzq_121 ._searchInput_ebbzq_121 {
        width: 100%;
    }
}

@media only screen and (max-width: 1200px) {
    ._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 ._search_ebbzq_121 {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    ._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 ._search_ebbzq_121, ._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._pillsAndSearchRow_ebbzq_29 ._search_ebbzq_121 ._searchInput_ebbzq_121 {
        width: 100%;
    }
}

._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._recentBlogs_ebbzq_143 {
    padding-bottom: 2rem;
}

@media only screen and (max-width: 480px) {
    ._blogsPage_ebbzq_9 ._blogListContainer_ebbzq_29 ._recentBlogs_ebbzq_143 {
        padding-bottom: 4rem;
    }
}

._container_ebbzq_152 {
    grid-column: span 12;
    padding-bottom: 4rem;
}

@media only screen and (max-width: 1200px) {
    ._container_ebbzq_152 {
        grid-column: span 8;
    }
}

@media only screen and (max-width: 480px) {
    ._container_ebbzq_152 {
        grid-column: span 4;
        padding-bottom: 4rem;
    }
}

._container_ebbzq_152 ._heading_ebbzq_167 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 120%;
    color: rgb(9, 20, 50);
    letter-spacing: 0.031rem;
}

._container_ebbzq_152 ._cardsContainer_ebbzq_176 {
    padding: 1.5rem 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem 1rem;
}

@media only screen and (max-width: 1200px) {
    ._container_ebbzq_152 ._cardsContainer_ebbzq_176 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 480px) {
    ._container_ebbzq_152 ._cardsContainer_ebbzq_176 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 1rem;
    }
}

._container_ebbzq_152 ._buttonContainer_ebbzq_196 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 0px;
}

._container_ebbzq_152 ._buttonContainer_ebbzq_196 ._viewMore_ebbzq_78 {
    width: 6.25rem;
    height: 3rem;
}
