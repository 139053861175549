._homePage_tmpov_9 ._heroSectionGradient_tmpov_9 {
  background: linear-gradient(
    60deg,
    rgba(10, 14, 15, 0.76) 0%,
    rgba(10, 14, 15, 0.64) 24.68%,
    rgba(10, 14, 15, 0.56) 48.18%,
    rgba(10, 14, 15, 0.4) 72.22%,
    rgba(10, 14, 15, 0.24) 100%
  );
  height: 100vh;
}
._heroSection_10juh_9 ._heroSectionGradient_tmpov_sub {
  background: linear-gradient(
    60deg,
    rgba(10, 14, 15, 0.76) 0%,
    rgba(10, 14, 15, 0.64) 24.68%,
    rgba(10, 14, 15, 0.56) 48.18%,
    rgba(10, 14, 15, 0.4) 72.22%,
    rgba(10, 14, 15, 0.24) 100%
  );
  height: 77vh;
}

._homePage_tmpov_9 ._heroSectionContentContainer_tmpov_12 {
  padding-top: 11.05rem;
  padding-bottom: 7.05rem;
}

@media only screen and (max-width: 1200px) {
  ._homePage_tmpov_9 ._heroSectionContentContainer_tmpov_12 {
    width: 100%;
    margin: 0;
    padding: 11.5rem 1.5rem 9rem;
    display: grid;
    column-gap: 16px;
    grid-template-columns: repeat(8, 1fr);
  }
}

@media only screen and (max-width: 480px) {
  ._homePage_tmpov_9 ._heroSectionContentContainer_tmpov_12 {
    padding: 8.95rem 1rem 5.45rem;
    grid-template-columns: repeat(4, 1fr);
  }
}

._homePage_tmpov_9
  ._heroSectionContentContainer_tmpov_12
  ._contactUsBtnClass_tmpov_32 {
  transition-duration: 0.3s;
  font-family: Roboto-bold;
  background-color: rgb(98 170 93);
}

._homePage_tmpov_9
  ._heroSectionContentContainer_tmpov_12
  ._contactUsBtnClass_tmpov_32
  ._contactUsBtnIconClass_tmpov_36 {
  transition-duration: 0.3s;
}

@media only screen and (min-width: 1200px) {
  ._homePage_tmpov_9
    ._heroSectionContentContainer_tmpov_12
    ._contactUsBtnClass_tmpov_32:hover
    ._contactUsBtnIconClass_tmpov_36 {
    transform: translate(6rem);
  }
}

@media only screen and (max-width: 480px) {
  ._homePage_tmpov_9 ._ourServicesBtnContainer_tmpov_45 {
    padding-top: 3rem;
  }
}

@media only screen and (max-width: 1200px) {
  ._homePage_tmpov_9 ._caseStudyContainer_tmpov_50 {
    padding: 4.5rem 0 4.5rem 1.5rem;
  }
}

@media only screen and (max-width: 480px) {
  ._homePage_tmpov_9 ._caseStudyContainer_tmpov_50 {
    padding: 0rem 0rem 4rem 1rem;
  }
}

._orangeColor_tmpov_60 {
  color: #fab914;
}
