._contactUsPage_1p4va_9 ._heroSectionGradient_1p4va_9 {
}

@media only screen and (max-width: 480px) {
    ._contactUsPage_1p4va_9 ._heroSectionGradient_1p4va_9 {
        background:linear-gradient(72deg,rgba(0,0,0,.64) 0%,rgba(0,0,0,.48) 36.31%,rgba(0,0,0,.24) 100%)
    }
}

._contactUsPage_1p4va_9 ._heroSectionGradient_1p4va_9 ._heroSectionContentContainer_1p4va_17 {
    padding-top: 13.125rem;
    padding-bottom: 12.937rem
}

@media only screen and (max-width: 1200px) {
    ._contactUsPage_1p4va_9 ._heroSectionGradient_1p4va_9 ._heroSectionContentContainer_1p4va_17 {
        width:100%;
        margin: 0;
        padding: 10.25rem 1.5rem;
        display: grid;
        column-gap: 1rem;
        grid-template-columns: repeat(8,1fr)
    }
}

@media only screen and (max-width: 480px) {
    ._contactUsPage_1p4va_9 ._heroSectionGradient_1p4va_9 ._heroSectionContentContainer_1p4va_17 {
        padding:8.93rem 1rem 5.43rem;
        grid-template-columns: repeat(4,1fr)
    }
}

._contactUsPage_1p4va_9 ._contactUsContainer_1p4va_37 {
    padding: 7rem auto
}

@media only screen and (max-width: 1200px) {
    ._contactUsPage_1p4va_9 ._contactUsContainer_1p4va_37 {
        padding:4.5rem auto
    }
}

@media only screen and (max-width: 480px) {
    ._contactUsPage_1p4va_9 ._contactUsContainer_1p4va_37 {
        padding:4rem auto
    }
}
