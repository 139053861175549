.bx-wrapper .bx-pager {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 20px 0;
}

.bx-wrapper .bx-pager-item {
  font-size: 0;
  line-height: 0;
  margin-bottom: 20px;
}

.bx-wrapper .bx-pager-link {
  background: #bfc3c7;
  cursor: pointer;
  display: block;
  height: 2px;
  margin: 0 5px;
  width: 20px;
}

.bx-wrapper .bx-pager-link.active {
  background: #f59000;
}

.bx-clone-h3 {
  color: #222;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

[data-slider]:not(.bx-slider-init) {
  overflow-y: hidden;
}

.page-main p.b-overview__item-amount-title {
  color: rgb(98 170 93);
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
  text-align: left;
}

@media (min-width: 768px) {
  .page-main p.b-overview__item-amount-title {
    font-size: 32px;
    line-height: 1.25;
  }
}

@media (min-width: 1025px) {
  .page-main p.b-overview__item-amount-title {
    font-size: 40px;
    line-height: 1em;
  }
}

.page-main p.b-overview__item-label {
  color: #333;
  font-size: 14px;
  line-height: 1.27;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .page-main p.b-overview__item-label {
    font-size: 16px;
    line-height: 1.38;
  }
}

@media (min-width: 1025px) {
  .page-main p.b-overview__item-label {
    font-size: 18px;
    line-height: 1.6;
  }
}

.b-overview {
  margin-bottom: 30px;
  padding-top: 30px;
}

.b-overview__list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.b-overview__item {
  border-left: 2px solid #e1e1e1;
  flex-basis: 100%;
  margin: 0 10px;
  padding: 0 1em;
  text-align: center;
}

.b-overview__item-admin {
  display: flex;
}

.b-overview__item-admin span {
  color: #b7b1b1;
  margin: 0 20px;
}

.b-overview__item:not(.visible) {
  display: none;
}

.b-overview__item-amount {
  color: rgb(98 170 93);
  font-size: 40px;
  font-weight: 600;
  text-align: left;
}

.b-overview__item-label {
  color: #333;
  font-size: 14px;
  line-height: 1.27;
  margin: 0 auto;
  text-align: left;
}

.b-overview__item-label span {
  color: #b7b1b1;
  font-size: 18px;
  margin: 30px 20px 0;
}

.b-overview__controls {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  list-style: none;
}

.b-overview__controls-item {
  margin: 1em 8.5px;
}

.b-overview__controls-item:hover {
  cursor: pointer;
}

.b-overview__controls-item-el {
  background-color: #e1e1e1;
  display: inline-block;
  height: 10px;
  width: 10px;
}

.b-overview__controls-item-el.active {
  background-color: rgb(98 170 93);
}

.b-overview .slick-dots {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  list-style: none;
  width: 100%;
}

.b-overview .slick-dots li {
  background-color: #e1e1e1;
  display: inline-block;
  height: 10px;
  margin: 1em 8.5px;
  width: 10px;
}

.b-overview .slick-dots li:last-child {
  margin-right: 0;
}

.b-overview .slick-dots li:first-child {
  margin-left: 0;
}

.b-overview .slick-dots li:hover {
  cursor: pointer;
}

.b-overview .slick-dots li.slick-active {
  background-color: rgb(98 170 93);
}

.b-overview .slick-dots li button {
  display: none;
}

@media (min-width: 768px) {
  .b-overview {
    margin-left: 1em;
    margin-right: 1em;
  }

  .b-overview.b-overview_center .b-overview__list {
    justify-content: center !important;
  }

  .b-overview__controls {
    display: none;
  }

  .b-overview__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .b-overview__item {
    box-sizing: border-box;
    display: block;
    margin: 0;
    margin-bottom: 30px !important;
  }

  .b-overview__item:not(.visible) {
    display: block;
  }

  .b-overview__item.auto {
    flex: 1 1;
  }

  .b-overview__item.one {
    max-width: 100%;
    width: 100%;
  }

  .b-overview__item.two {
    max-width: 50%;
    width: 50%;
  }

  .b-overview__item.three {
    max-width: 33.33333%;
    width: 33.33333%;
  }

  .b-overview__item.four {
    max-width: 25%;
    width: 25%;
  }

  .b-overview__item-admin {
    max-width: unset;
  }

  .b-overview__item-amount {
    font-size: 32px;
    line-height: 1.25;
  }

  .b-overview__item-amount-number {
    opacity: 0;
  }

  .b-overview__item-amount-symb1,
  .b-overview__item-amount-symb2 {
    opacity: 0;
    transition: opacity 2s ease;
  }

  .b-overview__item-label {
    font-size: 16px;
    line-height: 1.38;
    margin-top: 20px;
  }

  .b-overview__item:nth-last-child(2):first-child,
  .b-overview__item:nth-last-child(2):first-child ~ .b-overview__item {
    max-width: 50%;
  }
}

@media (min-width: 1025px) {
  .b-overview {
    margin-left: 0;
    margin-right: 0;
  }

  .b-overview__item-amount {
    font-size: 40px;
    line-height: 1em;
  }

  .b-overview__item-label {
    font-size: 18px;
    line-height: 1.6;
    margin-top: 20px;
  }
}

@media (min-width: 1441px) {
  .b-overview__item {
    padding: 0 1.5em;
  }

  .b-overview__item-label {
    font-size: 18px;
  }
}

.icp .b-overview ul:not(.x-list-plain) li:before,
.icp .b-overview ul li:before {
  display: none;
}

/* services */
.a-services {
  background-color: #fafafa;
  overflow-x: hidden;
  padding: 50px 0;
}

.a-services__tabs {
  margin-top: 40px;
}

.a-services__tabs-label {
  padding-left: 50px;
  text-decoration: none;
}

.a-services__tabs-container {
  margin: 20px auto 0;
}

.a-services__tabs-container > ul {
  list-style: none;
  margin: 16px 0;
  padding: 0;
}

.a-services__tabs-container > ul li {
  display: inline-flex;
}

.a-services__tabs .bx-viewport,
.a-services__tabs .bx-wrapper {
  height: 100% !important;
}

.a-services__content,
.a-services__content-container {
  height: 100%;
}

.a-services__name {
  color: #222;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 0 !important;
}

@media only screen and (min-width: 1023px) {
  .a-services__name {
    line-height: 1.27;
  }
}

@media only screen and (min-width: 1281px) {
  .a-services__name {
    font-size: 24px;
    line-height: 1.17;
  }
}

@media only screen and (max-width: 768px) {
  .a-services__name {
    line-height: normal;
  }
}

.a-services__name:after {
  background-color: rgb(65 100 78);
  content: "";
  display: block;
  height: 2px;
  margin: 12px 0 0;
  width: 40px;
}

.a-services__description {
  font-size: 18px;
  line-height: 1.78;
  margin-bottom: 19px;
  text-align: left;
}

@media only screen and (max-width: 1023px) {
  .a-services__description {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .a-services__description {
    font-size: 14px;
    line-height: 1.71;
  }
}

.a-services__bullet {
  flex: 1 100%;
  margin: 0 15px 20px 23px;
}

.a-services__bullet:before {
  background-color: rgb(65 100 78);
  content: " ";
  display: block;
  height: 8px;
  margin-left: -23px;
  transform: translateY(14px);
  width: 8px;
}

@media only screen and (min-width: 1024px) {
  .a-services__bullet {
    font-size: 18px;
    font-weight: 600;
    max-width: calc(50% - 38px);
  }
}

@media only screen and (max-width: 1023px) {
  .a-services__bullet {
    font-size: 16px;
    font-weight: 400;
  }
}

@media only screen and (max-width: 767px) {
  .a-services__bullet {
    font-size: 14px;
    font-weight: 600;
  }
}

.a-services__bullet:before {
  transform: translateY(17px);
}

.a-services__bullet a {
  color: #333;
  filter: grayscale(100%);
  text-decoration: underline;
  text-decoration-color: rgb(98 170 93);
  text-underline-offset: 2px;
}

.a-services__bullet a:hover {
  color: rgb(65 100 78);
  filter: grayscale(0);
}

.a-services__link {
  margin-top: auto;
  text-align: right;
}

.a-services__link a {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
}

.a-services__link a,
.a-services__link a:active,
.a-services__link a:visited {
  color: rgb(98 170 93);
}

.a-services__link a i {
  font-size: 12px;
  margin-left: 10px;
}

@media (min-width: 768px) {
  .a-services__tabs-label.active {
    transform: translateX(0);
    transition-delay: unset !important;
  }

  .waypoint .a-services__tabs-container.step-in-left {
    transform: translateX(-100px) translateY(-30px);
  }

  .a-services.waypoint-reveal .a-services__tabs-label.active {
    transform: translateX(20px);
  }

  .a-services.waypoint-reveal .a-services__tabs-container {
    transform: translateX(-30px) translateY(-30px);
  }

  .a-services__tabs {
    background: #fff;
    box-shadow: 0 2px 37px 0 #0000001a;
    margin-left: 30px;
    margin-top: 70px;
  }

  .a-services__tabs-container {
    background-color: rgb(65 100 78);
    box-shadow: 0 2px 37px 0 #0006;
    display: inline-block;
    flex: 0 0 256px;
    margin: 0;
    max-width: 256px;
    padding: 14px 20px 2px 0;
    transform: translateX(-30px) translateY(-30px);
    vertical-align: top;
  }

  .a-services__tabs-container > ul {
    list-style: none;
  }

  .a-services__tabs-container > ul li {
    display: block;
    margin-left: 0;
  }

  .a-services__tabs-label {
    background-color: rgb(65 100 78);
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 18px;
    font-weight: 600;
    height: 100%;
    padding: 16px 0 16px 34px;
    width: 100%;
  }

  .a-services__tabs-label p {
    display: inline;
  }

  .a-services__tabs-label:active p,
  .a-services__tabs-label:focus p,
  .a-services__tabs-label:hover p {
    border-bottom: 1px solid #fff;
  }

  .a-services__tabs-label.first {
    background-color: rgb(65 100 78);
  }

  .a-services__tabs-label.active {
    background-color: rgb(98 170 93);
    box-shadow: 0 1.5rem 2.125rem #0303033d;
    color: #fff;
    padding: 16px 40px 16px 16px;
    text-decoration: none;
    transform: translateX(20px);
    width: calc(100% + 20px);
  }

  .a-services__content {
    padding-left: 34px;
    padding-top: 50px;
  }

  .a-services__content-block {
    display: inline-block;
  }

  .a-services__name {
    font-size: 24px;
    margin-top: 0;
  }

  .a-services__link {
    margin-bottom: 25px;
  }
}

@media (min-width: 1600px) {
  .a-services__content-block {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%;
  }
}

@media (min-width: 1023px) {
  .a-services__tabs-container {
    flex: 0 0 303px;
    max-width: 303px;
  }

  .a-services__content {
    padding-left: 37px;
  }

  .a-services.waypoint-reveal .a-services__tabs-label.active {
    transform: translateX(30px);
  }
}

@media (min-width: 1279px) {
  .a-services.waypoint-reveal .a-services__tabs-label.active {
    transform: translateX(30px);
  }

  .a-services__content {
    padding-left: 70px;
  }

  .a-services__tabs-container {
    flex: 0 0 370px;
    max-width: 370px;
  }

  .a-services__tabs-label {
    padding: 16px 14px 16px 50px;
  }

  .a-services__tabs-label.active {
    padding: 16px 44px 16px 20px;
    transform: translateX(30px);
  }
}

@media (max-width: 1023px) {
  .a-services__tabs-label {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .a-services {
    padding: 30px 0 50px;
  }

  .a-services .bx-wrapper {
    margin-left: auto;
    margin-right: 40px;
  }

  .a-services__tabs-container {
    display: none;
  }

  .a-services__tabs .bx-viewport {
    overflow: visible !important;
  }

  .a-services__tabs .bx-pager {
    align-items: center;
    margin-left: -8px;
    padding: 20px 0 0;
    text-align: center;
    width: 100vw;
  }

  .a-services__tabs .bx-pager-item {
    display: inline-block;
    font-size: 0;
    line-height: 0;
    vertical-align: bottom;
  }

  .a-services__tabs .bx-pager-link {
    background: #bfc3c7;
    display: block;
    height: 2px;
    margin: 0 5px;
    outline: 0;
    text-indent: -9999px;
    width: 20px;
  }

  .a-services__tabs .bx-pager-link.active {
    background: #f59000;
  }

  .a-services__tabs [data-slider-item] {
    background: #fff;
    box-shadow: 0 2px 37px 0 #0000001a;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    width: calc(100vw - 43px) !important;
  }

  .a-services__content {
    padding: 20px 26px 31px;
  }

  .a-services__link {
    text-align: center;
  }
}

@media (min-width: 1023px) and (max-width: 1279px) {
  .a-services .a-services__tabs-label:not(.active) {
    padding: 16px 0 16px 46px;
  }
}

.page-main .a-services .a-services__bullet-a {
  font-size: 18px;
  line-height: normal;
}

@media only screen and (max-width: 767px) {
  .page-main .a-services .a-services__bullet-a {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .page-main .a-services .a-services__bullet-a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .page-main .a-services .a-services__description p,
  .page-main .a-services .a-services__description ul li {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .page-main .a-services .a-services__description p,
  .page-main .a-services .a-services__description ul li {
    font-size: 16px;
  }
}

.page-main .a-services .a-services__description p:last-child,
.page-main .a-services .a-services__description ul li:last-child {
  margin-bottom: 0;
}

.page-main .a-services .a-services__bullet {
  font-size: 18px;
  line-height: normal;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .page-main .a-services .a-services__bullet {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .page-main .a-services .a-services__bullet {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .page-main .a-services .a-services__bullet:before {
    transform: translateY(15px);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .page-main .a-services .a-services__bullet:before {
    transform: translateY(17px);
  }
}

/*  */
.a-section__description {
  font-size: 18px;
  line-height: 2;
}

@media only screen and (max-width: 1023px) {
  .a-section__description {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .a-section__description {
    font-size: 14px;
    line-height: 1.71;
  }
}

.page-main .a-image-text {
  box-sizing: border-box;
  margin: auto;
  overflow: hidden;
  padding: 130px 0 50px;
}

@media (min-width: 768px) {
  .page-main .a-image-text.waypoint .left .step-in-left {
    transform: translateX(-100px) translateY(-20px);
  }
}

@media (min-width: 1024px) {
  .page-main .a-image-text.waypoint .left .step-in-left {
    transform: translateX(-100px) translateY(-30px);
  }
}

@media (min-width: 768px) {
  .page-main .a-image-text.waypoint .right .step-in-right {
    transform: translateX(100px) translateY(-20px);
  }
}

@media (min-width: 1024px) {
  .page-main .a-image-text.waypoint .right .step-in-right {
    transform: translateX(100px) translateY(-30px);
  }
}

.page-main .a-image-text .l-container {
  display: flex;
}

@media (min-width: 768px) {
  .page-main .a-image-text__link {
    margin-top: 24px;
  }
}

.page-main .a-image-text__link a {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
}

.page-main .a-image-text__link a,
.page-main .a-image-text__link a:active,
.page-main .a-image-text__link a:visited {
  color: rgb(98 170 93);
}

.page-main .a-image-text__link a i {
  font-size: 12px;
  margin-left: 10px;
}

.page-main .a-image-text__link.left {
  text-align: right;
}

@media (max-width: 767px) {
  .page-main .a-image-text__link.left {
    text-align: center;
  }
}

.page-main .a-image-text .right .a-image-text__image {
  order: 1;
}

.page-main .a-image-text .left .a-image-text__image {
  order: 0;
}

@media (min-width: 768px) {
  .page-main
    .a-image-text.waypoint-reveal
    .left
    .a-image-text__image.step-in-left {
    transform: translateX(-20px) translateY(-20px);
  }
}

@media (min-width: 1024px) {
  .page-main
    .a-image-text.waypoint-reveal
    .left
    .a-image-text__image.step-in-left {
    transform: translateX(-30px) translateY(-30px);
  }
}

@media (min-width: 768px) {
  .page-main
    .a-image-text.waypoint-reveal
    .right
    .a-image-text__image.step-in-right {
    transform: translateX(20px) translateY(-20px);
  }
}

@media (min-width: 1024px) {
  .page-main
    .a-image-text.waypoint-reveal
    .right
    .a-image-text__image.step-in-right {
    transform: translateX(35px) translateY(-30px);
  }
}

@media (min-width: 1280px) {
  .page-main
    .a-image-text.waypoint-reveal
    .right
    .a-image-text__image.step-in-right {
    transform: translateX(45px) translateY(-30px);
  }
}

.page-main .a-image-text__image {
  background: #fff;
  box-shadow: 0 2px 15px 0 #0000001a;
  height: auto;
  line-height: 0;
  padding: 18px 20px;
  position: relative;
  text-align: center;
  z-index: 10;
}

@media (min-width: 768px) {
  .page-main .a-image-text__image {
    padding: 20px;
    width: 44.884%;
  }
}

@media (min-width: 1024px) {
  .page-main .a-image-text__image {
    padding: 30px;
    width: 44.256%;
  }
}

@media (min-width: 1280px) {
  .page-main .a-image-text__image {
    width: 43.86%;
  }
}

@media (min-width: 1600px) {
  .page-main .a-image-text__image {
    padding: 35px;
    width: 43.573%;
  }
}

.page-main .a-image-text__image img {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
}

.page-main .a-image-text__image.left {
  order: 0;
}

.page-main .a-image-text__image.right {
  order: 1;
}

.page-main .a-image-text__content {
  align-self: stretch;
  margin-left: 4px;
  margin-top: 22px;
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .page-main .a-image-text__content p {
    font-size: 18px;
  }
}

.page-main .a-image-text__content ul {
  column-count: 2;
  margin-top: 20px;
  padding-left: 0;
}

@media (max-width: 767px) {
  .page-main .a-image-text__content ul {
    column-count: 1;
    margin-bottom: 20px;
  }

  .page-main .a-image-text__content ul li:last-child {
    margin-bottom: 0;
  }
}

.page-main .a-image-text__content ul li {
  list-style: none;
  margin-bottom: 20px;
  margin-left: 0;
  position: relative;
}

/* .page-main .a-image-text__content ul li:before {
    background-color: rgb(98 170 93);
    content: "";
    display: inline-block;
    height: 8px;
    left: 0;
    margin: 0;
    position: absolute;
    top: 15px;
    vertical-align: middle;
    width: 8px
} */

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .page-main .a-image-text__content ul li {
    font-size: 18px;
  }

  .page-main .a-image-text__content ul li:before {
    top: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .page-main .a-image-text__content ul li:before {
    top: 11px;
  }
}

@media only screen and (max-width: 767px) {
  .page-main .a-image-text__content ul li:before {
    top: 9px;
  }
}

.page-main .a-image-text__content a:not(.btn-link-arrow) {
  color: #333;
  filter: grayscale(100%);
  text-decoration: underline;
  text-decoration-color: rgb(98 170 93);
  text-underline-offset: 2px;
}

.page-main .a-image-text__content a:not(.btn-link-arrow):hover {
  color: rgb(98 170 93);
  filter: grayscale(0);
}

@media (min-width: 768px) {
  .page-main .a-image-text__content {
    margin: 0;
    width: 55.2%;
  }

  .page-main .a-image-text__content.step-in-right {
    padding: 26px 35px 40px 12px;
  }
}

@media (min-width: 1024px) {
  .page-main .a-image-text__content.step-in-right {
    padding: 53px 29px 40px 18px;
  }
}

@media (min-width: 1280px) {
  .page-main .a-image-text__content.step-in-right {
    padding: 55px 99px 40px 66px;
  }
}

@media (min-width: 1600px) {
  .page-main .a-image-text__content.step-in-right {
    padding: 50px 101px 40px 87px;
  }
}

@media (min-width: 768px) {
  .page-main .a-image-text__content.step-in-left {
    padding: 50px 0 40px 40px;
  }
}

@media (min-width: 1024px) {
  .page-main .a-image-text__content.step-in-left {
    padding: 53px 3px 40px 40px;
  }
}

@media (min-width: 1280px) {
  .page-main .a-image-text__content.step-in-left {
    padding: 55px 51px 40px 40px;
  }
}

@media (min-width: 1600px) {
  .page-main .a-image-text__content.step-in-left {
    padding: 50px 72px 40px 40px;
  }
}

.page-main .a-image-text__content .h3 {
  margin: 0 0 23px;
}

.page-main .a-image-text__content .a-section__description {
  word-wrap: break-word;
  line-height: 1.75;
  margin-bottom: auto;
  text-align: left;
  word-break: break-word;
}

@media (min-width: 768px) {
  .page-main .a-image-text__content .a-section__description {
    line-height: 1.78;
  }

  .page-main .a-image-text__wrapper {
    align-items: flex-start;
    display: flex;
  }

  .page-main .a-image-text__wrapper.left {
    padding-left: 20px;
  }
}

@media (min-width: 1024px) {
  .page-main .a-image-text__wrapper.left {
    padding-left: 30px;
  }
}

@media (min-width: 768px) {
  .page-main .a-image-text__wrapper.right {
    padding-right: 20px;
  }
}

@media (min-width: 1024px) {
  .page-main .a-image-text__wrapper.right {
    padding-right: 30px;
  }
}

.page-main .a-image-text__bg {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
}

@media (min-width: 768px) {
  .page-main .a-image-text__bg {
    background: #fafafa;
    flex-direction: row;
  }
}

.page-main .a-image-text .edit-mode .pimcore_editable_image {
  height: 100% !important;
  width: 100% !important;
}

.page-main .a-image-text__description {
  flex-grow: 1;
}

.page-main .a-image-text__description p {
  color: #222;
  font-size: 18px;
  line-height: 1.78;
}

@media only screen and (max-width: 1023px) {
  .page-main .a-image-text__description p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .page-main .a-image-text__description p {
    font-size: 14px;
    line-height: 1.71;
  }
}

.page-main .a-image-text__description ul {
  margin-bottom: 0;
}

.page-main .a-image-text__description ul li {
  color: #222;
  font-size: 18px;
  line-height: 1.78;
}

@media only screen and (max-width: 1023px) {
  .page-main .a-image-text__description ul li {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .page-main .a-image-text__description ul li {
    font-size: 14px;
    line-height: 1.71;
  }
}

@media (max-width: 767px) {
  .page-main .a-image-text__description {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .page-main .a-image-text .a-section__title.h2 {
    color: #222;
    font-family: inherit !important;
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 20px;
  }

  .page-main .a-image-text .a-section__title.h2:after {
    background-color: rgb(65 100 78);
    content: "";
    display: block;
    height: 2px;
    margin: 10px 0 0;
    width: 40px;
  }

  .page-main .a-image-text .a-section__title.h2:before {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .page-main .a-image-text .a-section__title.h2 {
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  .page-main .a-image-text .a-section__title.h2 {
    margin-top: 20px;
  }

  .a-image-text {
    padding: 80px 0 50px;
  }

  .a-image-text__image {
    margin: 0 auto;
  }

  .a-image-text__image img {
    height: auto;
    width: unset;
  }

  .a-image-text__content {
    background: #fafafa;
    margin: 0 6px;
    padding: 30px 20px;
  }

  .a-image-text__link {
    margin-top: 0;
  }

  .a-image-text__link a {
    font-size: 14px;
  }

  .a-image-text .btn-link-arrow--right {
    padding-right: 23px;
  }
}
.page-main p.a-tabs-icons__tabs-title {
  margin-bottom: 0;
}

.page-main .a-tabs-icons__bullet:before {
  transform: translateY(21px);
}

@media (max-width: 767px) {
  .page-main .a-tabs-icons__bullet:before {
    transform: translateY(19px);
  }
}

.a-tabs-icons {
  padding: 50px 0;
}

.a-tabs-icons div[aria-hidden="true"][data-slider-item],
.a-tabs-icons li[aria-hidden="true"][data-slider-item] {
  visibility: hidden;
}

.a-tabs-icons .a-section__description {
  line-height: 1.67;
  text-align: left;
}

.a-tabs-icons__tabs {
  margin: 40px 0 50px;
}

.a-tabs-icons__tabs-label {
  text-align: center;
}

.a-tabs-icons__tabs-image {
  height: 70px;
  width: 70px;
}

.a-tabs-icons__tabs-title {
  color: #222;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  text-transform: capitalize;
}

@media only screen and (min-width: 1024px) {
  .a-tabs-icons__tabs-title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .a-tabs-icons__tabs-title {
    font-size: 18px;
  }
}

.a-tabs-icons__tabs-container {
  margin: 40px auto 0;
}

.a-tabs-icons__tabs-container > ul {
  list-style: none;
  margin: 16px 0;
  padding: 0;
}

.a-tabs-icons__tabs-container > ul li {
  display: inline-flex;
}

.a-tabs-icons__svg {
  pointer-events: none;
}

.a-tabs-icons__wrapper {
  align-items: center;
  background-size: cover;
  display: flex;
  height: 70px;
  justify-content: center;
  margin: 0 auto;
  width: 70px;
}

.a-tabs-icons__wrapper.custom-icon {
  background-position: 50% !important;
  background-size: cover;
}

.a-tabs-icons__wrapper--2 {
  background-position: 0 -140px;
}

.a-tabs-icons__tabs-label.active .a-tabs-icons__wrapper--2,
.a-tabs-icons__tabs-label:hover .a-tabs-icons__wrapper--2,
.a-tabs-icons__wrapper--2-mob {
  background-position: 0 -210px;
}

.a-tabs-icons__wrapper--3 {
  background-position: 0 -280px;
}

.a-tabs-icons__tabs-label.active .a-tabs-icons__wrapper--3,
.a-tabs-icons__tabs-label:hover .a-tabs-icons__wrapper--3,
.a-tabs-icons__wrapper--3-mob {
  background-position: 0 -350px;
}

.a-tabs-icons__wrapper--1 {
  background-position: 0 0;
}

.a-tabs-icons__tabs-label.active .a-tabs-icons__wrapper--1,
.a-tabs-icons__tabs-label:hover .a-tabs-icons__wrapper--1,
.a-tabs-icons__wrapper--1-mob {
  background-position: 0 -70px;
}

.a-tabs-icons__wrapper--4 {
  background-position: 0 -420px;
}

.a-tabs-icons__tabs-label.active .a-tabs-icons__wrapper--4,
.a-tabs-icons__tabs-label:hover .a-tabs-icons__wrapper--4,
.a-tabs-icons__wrapper--4-mob {
  background-position: 0 -490px;
}

.a-tabs-icons__wrapper--5 {
  background-position: 0 -560px;
}

.a-tabs-icons__tabs-label.active .a-tabs-icons__wrapper--5,
.a-tabs-icons__tabs-label:hover .a-tabs-icons__wrapper--5,
.a-tabs-icons__wrapper--5-mob {
  background-position: 0 -630px;
}

.a-tabs-icons__wrapper--6 {
  background-position: 0 -700px;
}

.a-tabs-icons__tabs-label.active .a-tabs-icons__wrapper--6,
.a-tabs-icons__tabs-label:hover .a-tabs-icons__wrapper--6,
.a-tabs-icons__wrapper--6-mob {
  background-position: 0 -770px;
}

.a-tabs-icons__wrapper img {
  height: auto;
  max-width: 100%;
}

.a-tabs-icons__content[aria-hidden="false"] {
  display: flex;
  flex-direction: column;
}

.a-tabs-icons__content-container {
  display: flex;
}

.a-tabs-icons__description {
  font-size: 18px;
  line-height: 1.78;
}

@media only screen and (max-width: 1023px) {
  .a-tabs-icons__description {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .a-tabs-icons__description {
    font-size: 14px;
    line-height: 1.71;
  }
}

.a-tabs-icons__bullets {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.a-tabs-icons__bullets-title {
  color: #222;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.78;
  margin: 2.33em 0 18px;
  text-transform: uppercase;
}

.a-tabs-icons__bullets-title:after {
  background-color: rgb(65 100 78);
  content: "";
  display: block;
  height: 2px;
  margin: 6px 0 0;
  width: 40px;
}

.a-tabs-icons__bullet {
  flex: 1 100%;
  margin: 0 15px 20px 23px;
}

.a-tabs-icons__bullet:before {
  background-color: rgb(65 100 78);
  content: " ";
  display: block;
  height: 8px;
  margin-left: -23px;
  transform: translateY(14px);
  width: 8px;
}

@media only screen and (min-width: 1024px) {
  .a-tabs-icons__bullet {
    font-size: 18px;
    font-weight: 600;
    max-width: calc(50% - 38px);
  }
}

@media only screen and (max-width: 1023px) {
  .a-tabs-icons__bullet {
    font-size: 16px;
    font-weight: 400;
  }
}

@media only screen and (max-width: 767px) {
  .a-tabs-icons__bullet {
    font-size: 14px;
    font-weight: 600;
  }
}

.a-tabs-icons__bullet:before {
  transform: translateY(17px);
}

@media (max-width: 767px) {
  .a-tabs-icons__bullet:before {
    transform: translateY(15px);
  }
}

.a-tabs-icons__bullet a {
  color: #333;
  filter: grayscale(100%);
  text-decoration: underline;
  text-decoration-color: rgb(98 170 93);
  text-underline-offset: 2px;
}

.a-tabs-icons__bullet a:hover {
  color: rgb(98 170 93);
  filter: grayscale(0);
}

.a-tabs-icons__link {
  margin-top: auto;
  text-align: right;
}

.a-tabs-icons__link a {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
}

.a-tabs-icons__link a,
.a-tabs-icons__link a:active,
.a-tabs-icons__link a:visited {
  color: rgb(98 170 93);
}

.a-tabs-icons__link a i {
  font-size: 12px;
  margin-left: 10px;
}

@media (min-width: 768px) {
  .a-tabs-icons__tabs {
    background: #fff;
    box-shadow: 0 2px 37px 0 #0000001a;
  }

  .a-tabs-icons__tabs-container {
    z-index: 100;
  }

  .a-tabs-icons__tabs-container > ul {
    display: flex;
    flex: 1 1 100%;
    justify-content: flex-start;
    list-style: none;
  }

  .a-tabs-icons__tabs-container > ul li {
    flex: 0 123px;
    margin-left: 0;
  }

  .a-tabs-icons__tabs-title {
    min-height: 42px;
  }

  .a-tabs-icons__tabs-label {
    border-bottom: 4px solid #0000;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    margin: 0 auto;
    padding: 20px 0;
    text-decoration: none;
    width: 100px;
  }

  .a-tabs-icons__tabs-label:hover .a-tabs-icons__tabs-title {
    color: rgb(98 170 93);
  }

  .a-tabs-icons__tabs-label.active {
    border-bottom: 4px solid rgb(98 170 93);
    position: relative;
  }

  .a-tabs-icons__tabs-label.active .a-tabs-icons__tabs-title {
    color: rgb(98 170 93);
  }

  .a-tabs-icons__tabs-label.active:after {
    border-bottom: 22px solid #0000;
    border-left: 22px solid rgb(98 170 93);
    border-right-color: #0000;
    border-top-color: #0000;
    bottom: -11px;
    content: "";
    display: block;
    position: absolute;
    right: 41%;
    transform: rotate(-135deg);
  }

  .a-tabs-icons__content {
    padding: 33px 0 30px;
  }

  .a-tabs-icons__content-container {
    height: 100%;
  }

  .a-tabs-icons .bx-controls {
    display: none;
  }
}

@media (min-width: 769px) {
  .a-tabs-icons__tabs-label {
    width: 120px;
  }

  .a-tabs-icons__tabs-container > ul li {
    flex: 0 160px;
    margin-right: 2px;
  }
}

@media (min-width: 1025px) {
  .a-tabs-icons__tabs-container {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%;
  }

  .a-tabs-icons__tabs-container > ul li {
    flex: 0 170px;
    margin-right: 30px;
  }
}

@media (min-width: 1441px) {
  .a-tabs-icons__tabs-container {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%;
  }

  .a-tabs-icons__tabs-container > ul li {
    margin-right: 23px;
  }
}

@media (max-width: 1024px) {
  .a-tabs-icons__tabs-label {
    padding: 26px 20px;
  }

  .a-tabs-icons__content {
    padding: 40px 0 30px;
  }
}

@media (max-width: 768px) {
  .a-tabs-icons__tabs-label {
    padding: 16px 0 14px;
  }

  .a-tabs-icons .bx-wrapper {
    margin-left: auto;
    margin-right: 40px;
  }

  .a-tabs-icons__content {
    padding: 40px 0 30px;
  }
}

@media (max-width: 767px) {
  .a-tabs-icons {
    padding-bottom: 20px;
  }

  .a-tabs-icons__icon {
    flex: 0 0 70px !important;
    margin-right: 16px;
    max-width: 70px !important;
  }

  .a-tabs-icons__icon + .col-8 {
    flex: 0 0 calc(100% - 86px);
    max-width: calc(100% - 86px);
  }

  .a-tabs-icons__tabs {
    margin: 40px -8px;
  }

  .a-tabs-icons__tabs .bx-wrapper {
    max-width: 100% !important;
  }

  .a-tabs-icons__tabs-container {
    display: none;
  }

  .a-tabs-icons__tabs-title {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .a-tabs-icons__tabs-description {
    margin-top: 16px;
  }

  .a-tabs-icons__tabs .bx-viewport {
    overflow: visible !important;
  }

  .a-tabs-icons__tabs .bx-pager {
    align-items: center;
    margin-left: -8px;
    padding: 20px 0 0;
    text-align: center;
    width: 100vw;
  }

  .a-tabs-icons__tabs .bx-pager-item {
    display: inline-block;
    font-size: 0;
    line-height: 0;
    vertical-align: bottom;
  }

  .a-tabs-icons__tabs .bx-pager-link {
    background: #bfc3c7;
    display: block;
    height: 2px;
    margin: 0 5px;
    outline: 0;
    text-indent: -9999px;
    width: 20px;
  }

  .a-tabs-icons__tabs .bx-pager-link.active {
    background: #f59000;
  }

  .a-tabs-icons__tabs [data-slider-item] {
    background: #fff;
    box-shadow: 0 2px 37px 0 #0000001a;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    width: calc(100vw - 43px) !important;
  }

  .a-tabs-icons__bullets-title {
    margin: 30px 0;
  }

  .a-tabs-icons__content {
    margin-left: 8px;
    padding: 20px 26px 31px;
  }

  .a-tabs-icons__content-container {
    height: 100%;
  }

  .a-tabs-icons__link {
    text-align: center;
  }
}
.a-card {
  background-color: #fff;
  cursor: pointer;
  position: relative;
}

.a-card > * > * {
  z-index: 1;
}

.a-card:after {
  border-bottom: 4px solid #fff;
  bottom: 0;
  box-shadow: 0 2px 37px 0 #0000001a;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.a-card__title {
  color: #222;
  font-size: 22px;
  font-weight: 700;
}

@media only screen and (min-width: 1023px) {
  .a-card__title {
    line-height: 1.27;
  }
}

@media only screen and (min-width: 1281px) {
  .a-card__title {
    font-size: 24px;
    line-height: 1.17;
  }
}

@media only screen and (max-width: 768px) {
  .a-card__title {
    line-height: normal;
  }
}

.a-card__link {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
}

.a-card__link,
.a-card__link:active,
.a-card__link:visited {
  color: rgb(98 170 93);
}

.a-card__link i {
  font-size: 12px;
  margin-left: 10px;
}

@media only screen and (min-width: 769px) {
  .a-card:hover:after {
    border-bottom-color: rgb(98 170 93);
    box-shadow: 0 2px 30px 0 #0003;
  }

  .a-card:hover .btn-link-arrow--right:after {
    animation: slide-left 1s ease infinite;
  }

  .a-card:hover .btn-link-arrow--hide-label:after {
    animation: slide-left 1s ease-in-out 0.5s infinite;
  }

  .a-card:hover .btn-link-arrow--hide-label .btn__label {
    transform: translateX(0);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .a-card--blog.no-image {
    padding-left: 20px;
  }

  .a-card--blog.no-image .a-blog__list-content {
    max-width: 100%;
  }
}

.a-card--blog:before {
  background-color: rgb(98 170 93);
  bottom: 0;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  right: 100%;
  transition: right 0.4s ease-in-out;
}

.a-card--blog:after {
  border-bottom: none;
  box-shadow: 0 2px 15px 0 #0000001a;
}

.a-card--blog:hover:before {
  right: 0;
}

.a-card--blog:hover:after {
  box-shadow: 0 2px 25px 0 #0003;
}

@keyframes slide-left {
  0%,
  to {
    transform: translate(0);
  }

  50% {
    transform: translate(-5px);
  }
}

.dt-solution-card {
  align-items: center;
  border: 1px solid #dadfe3;
  color: #222;
  display: flex;
  flex-basis: calc(33% - 18px);
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  margin: 0 30px 30px 0;
  min-height: 100px;
  padding: 11px 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.dt-solution-card:not([href]) {
  cursor: auto;
}

.dt-solution-card i {
  bottom: 10px;
  color: rgb(98 170 93);
  font-size: 12px;
  height: 12px;
  position: absolute;
  right: 10px;
  transform: rotate(50deg);
  transition: color 0.2s, transform 0.2s;
}

.dt-solution-card i:before {
  vertical-align: text-bottom;
}

.dt-solution-card:hover {
  color: #222;
  text-decoration: none;
}

.dt-solution-card:hover i {
  transform: none;
}

.dt-solution-card:hover:after {
  box-shadow: 0 2px 5px 0 #0003;
  z-index: 0;
}

.dt-solution-card:nth-of-type(3n) {
  margin: 0 0 30px;
}

@media only screen and (min-width: 1023px) {
  .dt-solution-card {
    flex-basis: calc(25% - 18px);
    padding: 26px 7px;
  }

  .dt-solution-card,
  .dt-solution-card:nth-of-type(3n) {
    margin: 0 23px 30px 0;
  }

  .dt-solution-card:nth-of-type(4n) {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .dt-solution-card {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .dt-solution-card {
    flex: 1 1 100%;
    font-size: 14px;
    margin: 8px 0 0;
    min-height: 60px;
  }

  .dt-solution-card:nth-of-type(3n),
  .dt-solution-card:nth-of-type(4n) {
    margin: 8px 0 0;
  }

  .dt-solution-card:first-of-type {
    margin: 0;
  }
}

.home-page .dt-solution-card {
  border: none;
}

.home-page .dt-solution-card:hover {
  background-color: rgb(98 170 93);
  color: #fff;
}

.home-page .dt-solution-card:hover i {
  color: #fff;
}

.home-page .dt-solution-card:hover:after {
  box-shadow: none;
}

.dt-solution-card:after {
  border-bottom: none;
  box-shadow: none;
}

.blue .dt-solution-card {
  border: none;
}

.blue .dt-solution-card:hover {
  background-color: rgb(98 170 93);
  color: #fff;
}

.blue .dt-solution-card:hover i {
  color: #fff;
}

.blue .dt-solution-card:hover:after {
  box-shadow: none;
}

.white .dt-solution-card {
  border: 1px solid #dadfe3;
}

.white .dt-solution-card:hover {
  background-color: #fff;
  color: #222;
}

.white .dt-solution-card:hover i {
  color: rgb(98 170 93);
}

.white .dt-solution-card:hover:after {
  box-shadow: 0 2px 5px 0 #0003;
  z-index: 0;
}

.grey .dt-solution-card {
  border: 1px solid #dadfe3;
}

.grey .dt-solution-card:hover {
  background-color: #fff;
  color: #222;
}

.grey .dt-solution-card:hover i {
  color: rgb(98 170 93);
}

.grey .dt-solution-card:hover:after {
  box-shadow: 0 2px 5px 0 #0003;
  z-index: 0;
}

.a-solution-types.no-padding {
  padding-top: 0 !important;
}

.home-page .a-solution-types {
  padding: 50px 0 20px;
}

.a-solution-types .a-section__description {
  color: #222;
}

.home-page .a-solution-types {
  background-color: rgb(65 100 78);
}

.home-page .a-solution-types,
.home-page .a-solution-types .a-section__description,
.home-page .a-solution-types .h2 {
  color: #fff !important;
}

.a-solution-types.white {
  background-color: #fff;
}

.a-solution-types.white,
.a-solution-types.white .a-section__description,
.a-solution-types.white .h2 {
  color: #222 !important;
}

.a-solution-types.blue {
  background-color: rgb(65 100 78);
}

.a-solution-types.blue,
.a-solution-types.blue .a-section__description,
.a-solution-types.blue .h2 {
  color: #fff !important;
}

.a-solution-types.grey {
  background-color: #fafafa;
}

.a-solution-types.grey,
.a-solution-types.grey .a-section__description,
.a-solution-types.grey .h2 {
  color: #222 !important;
}

.a-solution-types .a-section__description {
  line-height: 1.78;
  padding-top: 24px;
}

.a-solution-types .a-section__title + .a-section__description {
  padding-top: 0;
}

.a-solution-types__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}

.a-solution-types__link {
  align-items: center;
  border: 1px solid #dadfe3;
  color: #222;
  display: flex;
  flex-basis: calc(33% - 18px);
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  margin: 0 30px 30px 0;
  min-height: 100px;
  padding: 11px 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.a-solution-types__link:not([href]) {
  cursor: auto;
}

.a-solution-types__link i {
  bottom: 10px;
  color: rgb(98 170 93);
  font-size: 12px;
  height: 12px;
  position: absolute;
  right: 10px;
  transform: rotate(50deg);
  transition: color 0.2s, transform 0.2s;
}

.a-solution-types__link i:before {
  vertical-align: text-bottom;
}

.a-solution-types__link:hover {
  color: #222;
  text-decoration: none;
}

.a-solution-types__link:hover i {
  transform: none;
}

.a-solution-types__link:hover:after {
  box-shadow: 0 2px 5px 0 #0003;
  z-index: 0;
}

.a-solution-types__link:nth-of-type(3n) {
  margin: 0 0 30px;
}

@media only screen and (min-width: 1023px) {
  .a-solution-types__link {
    flex-basis: calc(25% - 18px);
    margin: 0 23px 30px 0;
    padding: 26px 7px;
  }

  .a-solution-types__link:nth-of-type(3n) {
    margin: 0 23px 30px 0;
  }

  .a-solution-types__link:nth-of-type(4n) {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .a-solution-types__link {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .a-solution-types__link {
    flex: 1 1 100%;
    font-size: 14px;
    margin: 8px 0 0;
    min-height: 60px;
  }

  .a-solution-types__link:nth-of-type(3n),
  .a-solution-types__link:nth-of-type(4n) {
    margin: 8px 0 0;
  }

  .a-solution-types__link:first-of-type {
    margin: 0;
  }
}

.home-page .a-solution-types__link {
  border: none;
}

.home-page .a-solution-types__link:hover {
  background-color: rgb(98 170 93);
  color: #fff;
}

.home-page .a-solution-types__link:hover i {
  color: #fff;
}

.home-page .a-solution-types__link:hover:after {
  box-shadow: none;
}

.a-solution-types__link:after {
  border-bottom: none;
  box-shadow: none;
}

.blue .a-solution-types__link {
  border: none;
}

.blue .a-solution-types__link:hover {
  background-color: rgb(98 170 93);
  color: #fff;
}

.blue .a-solution-types__link:hover i {
  color: #fff;
}

.blue .a-solution-types__link:hover:after {
  box-shadow: none;
}

.white .a-solution-types__link {
  border: 1px solid #dadfe3;
}

.white .a-solution-types__link:hover {
  background-color: #fff;
  color: #222;
}

.white .a-solution-types__link:hover i {
  color: rgb(98 170 93);
}

.white .a-solution-types__link:hover:after {
  box-shadow: 0 2px 5px 0 #0003;
  z-index: 0;
}

.grey .a-solution-types__link {
  border: 1px solid #dadfe3;
}

.grey .a-solution-types__link:hover {
  background-color: #fff;
  color: #222;
}

.grey .a-solution-types__link:hover i {
  color: rgb(98 170 93);
}

.grey .a-solution-types__link:hover:after {
  box-shadow: 0 2px 5px 0 #0003;
  z-index: 0;
}

.a-solution-types.waypoint.waypoint-reveal .step-up:hover {
  transition: background 0.6s, color 0.6s;
}

.page-main .a-solution-types .a-solution-types__list a {
  line-height: normal;
}

@media only screen and (max-width: 767px) {
  .a-solution-types {
    padding: 30px 0 39px;
  }

  .a-solution-types__list {
    margin-top: 30px;
  }
}
