._privacyPolicyPage_f2rzd_9 {
    background-color: #f9fafd;
    overflow-x: hidden
}

._privacyPolicyPage_f2rzd_9 ._heroSectionGradient_f2rzd_13 {
    background: linear-gradient(60deg,rgba(8,78,16,.76) 0%,rgba(65,100,78,.64) 24.68%,rgba(50,95,47,.56) 48.18%,rgba(10,14,15,.4) 72.22%,rgba(10,14,15,.24) 100%)
}

._privacyPolicyPage_f2rzd_9 ._title_f2rzd_16 {
    font-size: 2rem!important;
    margin-bottom: 0!important;
    line-height: 120%
}

@media only screen and (max-width: 17.5rem) {
    ._privacyPolicyPage_f2rzd_9 ._title_f2rzd_16 {
        font-size:1.5rem!important
    }
}

._privacyPolicyPage_f2rzd_9 ._heroSectionContentContainer_f2rzd_26 {
    padding-top: 7.25rem;
    padding-bottom: 4.25rem
}

._privacyPolicyPage_f2rzd_9 ._cardContainer_f2rzd_30 {
    grid-row: 1;
    grid-column: 3/11;
    border-radius: .25rem;
    background: #fff;
    margin: 2rem 0;
    box-sizing: border-box
}

@media only screen and (max-width: 480px) {
    ._privacyPolicyPage_f2rzd_9 ._cardContainer_f2rzd_30 {
        grid-column:1/5
    }
}

@media only screen and (max-width: 1200px) {
    ._privacyPolicyPage_f2rzd_9 ._cardContainer_f2rzd_30 {
        grid-column:1/9
    }
}

._privacyPolicyPage_f2rzd_9 ._cardContainer_f2rzd_30 ._content_f2rzd_48 {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem
}

@media only screen and (max-width: 480px) {
    ._privacyPolicyPage_f2rzd_9 ._cardContainer_f2rzd_30 ._content_f2rzd_48 {
        padding:1.5rem
    }
}

._privacyPolicyPage_f2rzd_9 ._section_f2rzd_59 {
    display: flex;
    flex-direction: column;
    gap: 1rem
}

._privacyPolicyPage_f2rzd_9 ._section_f2rzd_59 ._sectionList_f2rzd_64 {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    font-size: .875rem
}

._privacyPolicyPage_f2rzd_9 ._section_f2rzd_59 ._sectionList_f2rzd_64 ._listItem_f2rzd_70 {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: .5rem
}

._privacyPolicyPage_f2rzd_9 ._section_f2rzd_59 ._sectionList_f2rzd_64 ._listItem_f2rzd_70 img {
    height: .5rem;
    width: .5rem
}

._privacyPolicyPage_f2rzd_9 ._section_f2rzd_59 ._sectionTitle_f2rzd_80 {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 1.25rem;
    letter-spacing: .5px;
    color: #07172d
}

._privacyPolicyPage_f2rzd_9 ._section_f2rzd_59 ._description_f2rzd_87 {
    font-size: .875rem;
    color: #07172d;
    letter-spacing: .5px;
    line-height: 140%
}
