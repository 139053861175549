._caseStudyContainer_1ny87_9 {
    padding: 7rem 0rem;
    row-gap: 3rem
}

@media only screen and (max-width: 1200px) {
    ._caseStudyContainer_1ny87_9 {
        padding:4.5rem 1.5rem
    }
}

@media only screen and (max-width: 480px) {
    ._caseStudyContainer_1ny87_9 {
        padding:4rem 1rem
    }
}

._caseStudyContainer_1ny87_9 ._titleContainer_1ny87_23 {
    grid-row: 1;
    grid-column: 1/7;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.5rem
}

@media only screen and (max-width: 1200px) {
    ._caseStudyContainer_1ny87_9 ._titleContainer_1ny87_23 {
        grid-column:1/9;
        gap: .5rem
    }
}

@media only screen and (max-width: 480px) {
    ._caseStudyContainer_1ny87_9 ._titleContainer_1ny87_23 {
        grid-column:1/5;
        gap: .5rem
    }
}

._caseStudyContainer_1ny87_9 ._title_1ny87_23 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 4rem;
    line-height: 120%;
    letter-spacing: .03125rem
}

@media only screen and (max-width: 480px) {
    ._caseStudyContainer_1ny87_9 ._title_1ny87_23 {
        font-family:Montserrat;
        font-style: normal;
        font-weight: 300;
        font-size: 3rem;
        line-height: 120%;
        letter-spacing: .03125rem
    }
}

@media only screen and (max-width: 17.5rem) {
    ._caseStudyContainer_1ny87_9 ._title_1ny87_23 {
        font-size:2.25rem
    }
}

._caseStudyContainer_1ny87_9 ._description_1ny87_66 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: .015625rem
}

._caseStudyContainer_1ny87_9 ._cardContainer_1ny87_74 {
    grid-row: 2;
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: repeat(12,1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    -ms-overflow-style: none;
    scrollbar-width: none
}

._caseStudyContainer_1ny87_9 ._cardContainer_1ny87_74::-webkit-scrollbar {
    display: none
}

@media only screen and (max-width: 1200px) {
    ._caseStudyContainer_1ny87_9 ._cardContainer_1ny87_74 {
        grid-auto-flow:column;
        overflow-x: auto;
        display: grid;
        grid-template-columns: repeat(8,1fr);
        column-gap: 1rem;
        row-gap: 1rem
    }
}

@media only screen and (max-width: 480px) {
    ._caseStudyContainer_1ny87_9 ._cardContainer_1ny87_74 {
        display:grid;
        grid-template-columns: auto;
        column-gap: 1rem;
        row-gap: 1rem
    }
}

._caseStudyContainer_1ny87_9 ._viewAllBtnContainer_1ny87_105 {
    grid-row: 3;
    margin: 0 auto;
    grid-column: 1/-1
}

._caseStudyContainer_1ny87_9 ._viewAllBtnContainer_1ny87_105 ._viewAllBtn_1ny87_105 {
    width: 6.25rem;
    height: 3rem
}

._darkThmeContainer_1ny87_115 {
    background-color: #07172d
}

._darkThmeContainer_1ny87_115 ._caseStudyContainer_1ny87_9 ._titleContainer_1ny87_23 ._title_1ny87_23,._darkThmeContainer_1ny87_115 ._caseStudyContainer_1ny87_9 ._titleContainer_1ny87_23 ._description_1ny87_66 {
    color: #fff
}

._darkThmeContainer_1ny87_115 ._caseStudyContainer_1ny87_9 ._viewAllBtnContainer_1ny87_105 ._themeBasedViewAllBtn_1ny87_124 {
    color: #fff!important;
    border-color: #fff!important
}

._darkThmeContainer_1ny87_115 ._caseStudyContainer_1ny87_9 ._viewAllBtnContainer_1ny87_105 ._themeBasedViewAllBtn_1ny87_124:hover {
    color: #2c74d6!important
}

._cardContainer_q0hui_9 {
    border-radius: .5rem;
    background: #2c74d6;
    box-shadow: 0 .125rem .25rem #00000014;
    grid-column: span 6;
    display: flex;
    min-width: 36.5rem;
    min-height: 27.188rem;
    width: 100%;
    height: 100%
}

@media only screen and (max-width: 480px) {
    ._cardContainer_q0hui_9 {
        display:flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 1.5rem;
        grid-column: span 3;
        min-width: 20.5rem;
        min-height: 31.375rem;
        width: 100%;
        height: 100%
    }
}

@media only screen and (max-width: 17.5rem) {
    ._cardContainer_q0hui_9 {
        min-width:15.12rem
    }
}

._cardContainer_q0hui_9 ._imageContainer_q0hui_38 {
    position: relative
}

@media only screen and (max-width: 480px) {
    ._cardContainer_q0hui_9 ._imageContainer_q0hui_38 {
        display:flex;
        justify-content: center;
        width: 100%
    }
}

._cardContainer_q0hui_9 ._imageContainer_q0hui_38 ._imageContent_q0hui_48 {
    position: absolute;
    z-index: 1;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 16.755rem;
    height: calc(100% - 3rem);
    object-fit: cover;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem
}

@media only screen and (max-width: 480px) {
    ._cardContainer_q0hui_9 ._imageContainer_q0hui_38 ._imageContent_q0hui_48 {
        width:calc(100% - 3rem)!important;
        height: 9.5rem;
        object-fit: cover;
        margin-top: 0;
        object-position: center;
        border-top-right-radius: 0;
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        margin-bottom: 0
    }
}

._cardContainer_q0hui_9 ._imageContainer_q0hui_38 ._imageBackground_q0hui_72 {
    border-radius: var(--xlarge, 1.5rem);
    background: var(--colors-ada-theme-key-colors-tertiary, #235dab);
    position: relative;
    top: 2.25rem;
    width: 17.75rem;
    height: calc(100% - 4.5rem)
}

@media only screen and (max-width: 480px) {
    ._cardContainer_q0hui_9 ._imageContainer_q0hui_38 ._imageBackground_q0hui_72 {
        height:10rem;
        top: 0;
        margin-left: 2.25rem;
        margin-right: 2.25rem
    }
}

._cardContainer_q0hui_9 ._contentContainer_q0hui_88 {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap
}

@media only screen and (max-width: 480px) {
    ._cardContainer_q0hui_9 ._contentContainer_q0hui_88 {
        flex-grow:1;
        padding: 0 1.5rem 1.5rem
    }
}

._cardContainer_q0hui_9 ._contentContainer_q0hui_88 ._title_q0hui_101 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 120%;
    color: #fff;
    letter-spacing: .5px
}

@media only screen and (max-width: 17.5rem) {
    ._cardContainer_q0hui_9 ._contentContainer_q0hui_88 ._title_q0hui_101 {
        font-size:1rem
    }
}

._cardContainer_q0hui_9 ._contentContainer_q0hui_88 ._subheading_q0hui_115 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    line-height: 120%;
    color: #fff;
    letter-spacing: .25px;
    padding: 1rem 0 2.5rem
}

@media only screen and (max-width: 480px) {
    ._cardContainer_q0hui_9 ._contentContainer_q0hui_88 ._subheading_q0hui_115 {
        font-family:Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 140%;
        color: #fff;
        letter-spacing: .25px;
        padding: .5rem 0 1.5rem
    }
}

._cardContainer_q0hui_9 ._contentContainer_q0hui_88 ._pillContainer_q0hui_137 {
    display: flex;
    gap: .5rem;
    flex-wrap: wrap
}

._cardContainer_q0hui_9 ._contentContainer_q0hui_88 ._pillContainer_q0hui_137 ._pills_q0hui_142 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: .75rem;
    line-height: 130%;
    color: #fff;
    letter-spacing: .25px;
    margin: 0;
    border: 1px solid #fff;
    border-radius: 1rem;
    padding: .375rem .75rem;
    width: fit-content
}

._cardContainer_q0hui_9 ._contentContainer_q0hui_88 ._readContainer_q0hui_156 ._read_q0hui_156 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: .875rem;
    line-height: 150%;
    color: #fff;
    letter-spacing: .5px;
    text-decoration: none
}

._cardContainer_q0hui_9 ._contentContainer_q0hui_88 ._readContainer_q0hui_156 ._read_q0hui_156 ._hideData_q0hui_166 {
    color: transparent
}
