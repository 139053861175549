._aboutUsPage_104ft_9 ._heroSectionGradient_104ft_9 {
    background: linear-gradient(60deg,rgba(8,78,16,.76) 0%,rgba(65,100,78,.64) 24.68%,rgba(50,95,47,.56) 48.18%,rgba(10,14,15,.4) 72.22%,rgba(10,14,15,.24) 100%)
}

@media only screen and (max-width: 480px) {
    ._aboutUsPage_104ft_9 ._heroSectionGradient_104ft_9 {
        background: linear-gradient(60deg,rgba(8,78,16,.76) 0%,rgba(65,100,78,.64) 24.68%,rgba(50,95,47,.56) 48.18%,rgba(10,14,15,.4) 72.22%,rgba(10,14,15,.24) 100%)
    }
}

._aboutUsPage_104ft_9 ._heroSectionContentContainer_104ft_17 {
    padding-top: 11.625rem;
    padding-bottom: 7.625rem
}

@media only screen and (max-width: 480px) {
    ._aboutUsPage_104ft_9 ._heroSectionContentContainer_104ft_17 {
        height:36.375rem;
        padding: 12.25rem 1rem
    }
}

._aboutUsPage_104ft_9 ._whoWeAreContainer_104ft_27 {
    padding: 7rem 0;
   
}

@media only screen and (max-width: 1200px) {
    ._aboutUsPage_104ft_9 ._whoWeAreContainer_104ft_27 {
        padding:7rem 1.5rem;
        
    }
}

@media only screen and (max-width: 480px) {
    ._aboutUsPage_104ft_9 ._whoWeAreContainer_104ft_27 {
        padding:4rem 1rem

    }
}

._bottomContainerDynamicClass_104ft_41 {
    background-color: #f9fafd!important
}
