._cardContainer_a4cvn_9 {
    grid-column: span 3;
    min-width: 17.75rem;
    border-radius: .5rem;
    /* border-bottom: .25rem solid #2c74d6; */
    box-shadow: 0 .25rem .5rem #00000014;
    display: flex;
    align-items: flex-start;
    flex-direction: column
}

@media only screen and (max-width: 1200px) {
    ._cardContainer_a4cvn_9 {
        min-width:15.6875rem
    }
}

@media only screen and (max-width: 480px) {
    ._cardContainer_a4cvn_9 {
        min-width:15.125rem
    }
}

._cardContainer_a4cvn_9 ._imageContainer_a4cvn_29 {
    display: flex;
    width: 100%
}

._cardContainer_a4cvn_9 ._imageContainer_a4cvn_29 ._image_a4cvn_29 {
    height: 10rem;
    width: 100%;
    border-top-left-radius: 2.5rem;
    border-top-right-radius: 0;
}

._cardContainer_a4cvn_9 ._contentWrapper_a4cvn_40 {
    padding: 1.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 2rem;
    flex-grow: 1
}

._cardContainer_a4cvn_9 ._contentWrapper_a4cvn_40 ._contentContainer_a4cvn_49 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: .5rem
}

._cardContainer_a4cvn_9 ._contentWrapper_a4cvn_40 ._contentContainer_a4cvn_49 ._title_a4cvn_55 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 120%;
    color: #111;
    letter-spacing: .5px;
    cursor: pointer
}

._cardContainer_a4cvn_9 ._contentWrapper_a4cvn_40 ._contentContainer_a4cvn_49 ._subtext_a4cvn_65 {
    max-height: 3.6em;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    line-height: 140%;
    color: #555;
    letter-spacing: .25px
}

._cardContainer_a4cvn_9 ._contentWrapper_a4cvn_40 ._contentContainer_a4cvn_49 ._pillContainer_a4cvn_80 {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    flex-wrap: wrap
}

@media only screen and (max-width: 1200px) {
    ._cardContainer_a4cvn_9 ._contentWrapper_a4cvn_40 ._contentContainer_a4cvn_49 ._pillContainer_a4cvn_80 {
        margin-top:.5rem
    }
}

._cardContainer_a4cvn_9 ._contentWrapper_a4cvn_40 ._contentContainer_a4cvn_49 ._pillContainer_a4cvn_80 ._pills_a4cvn_92 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: .75rem;
    line-height: 130%;
    color: #555;
    letter-spacing: .25px;
    margin: 0;
    border-radius: 1rem;
    padding: .375rem .75rem;
    width: fit-content;
    border: 1px solid var(--colors-ada-theme-key-colors-secondary, #e4eaf2)
}

._cardContainer_a4cvn_9 ._contentWrapper_a4cvn_40 ._contentContainer_a4cvn_49 ._authorInfoContainer_a4cvn_106 {
    padding: 1rem 0 0
}

._cardContainer_a4cvn_9 ._contentWrapper_a4cvn_40 ._readmore_a4cvn_109 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: .875rem;
    line-height: 150%;
    color: rgb(65 100 78);
    letter-spacing: .25px;
    text-decoration: none
}

._cardContainer_a4cvn_9 ._contentWrapper_a4cvn_40 ._readmore_a4cvn_109 ._hideData_a4cvn_119 {
    color: transparent
}

._cardContainer_a4cvn_9._darkCard_a4cvn_122 {
    background: #07172d;
    box-shadow: 0 1.5rem 2.125rem #0303033d
}

._cardContainer_a4cvn_9._darkCard_a4cvn_122 ._contentWrapper_a4cvn_40 ._contentContainer_a4cvn_49 ._title_a4cvn_55 {
    color: #fff;
    cursor: pointer
}

._cardContainer_a4cvn_9._darkCard_a4cvn_122 ._contentWrapper_a4cvn_40 ._contentContainer_a4cvn_49 ._subtext_a4cvn_65 {
    color: #bbb
}

._cardContainer_a4cvn_9._darkCard_a4cvn_122 ._contentWrapper_a4cvn_40 ._contentContainer_a4cvn_49 ._pillContainer_a4cvn_80 ._pills_a4cvn_92 {
    border-color: #bbb;
    color: #bbb
}
