._whitepapersPage_152x6_9 ._heroSectionContentContainer_152x6_9 {
    padding-top: 15.75rem;
    padding-bottom: 15.75rem;
}

@media only screen and (max-width: 480px) {
    ._whitepapersPage_152x6_9 ._heroSectionContentContainer_152x6_9 {
        height:36.375rem
    }
}

._whitepapersPage_152x6_9 ._featuredWhitepapersWrapper_152x6_18 {
    background-color: #f9fafd
}

._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 {
    grid-column: span 12;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 4rem 0;
    
}

@media only screen and (max-width: 1200px) {
    ._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 {
        grid-column:span 8;
        display: flex;
        align-items: flex-start;
        flex-direction: column-reverse;
        gap: 3rem
    }
}

@media only screen and (max-width: 480px) {
    ._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 {
        border-bottom:unset;
        padding: 4rem 0rem;
        grid-column: span 4;
        display: flex;
        align-items: flex-start;
        flex-direction: column-reverse;
        gap: 3rem
    }
}

._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 ._pillContainer_152x6_48 {
    width: 70%;
    grid-column: span 12;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: .5rem;
    row-gap: 1rem
}

@media only screen and (max-width: 1200px) {
    ._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 ._pillContainer_152x6_48 {
        width:100%;
        grid-column: span 8
    }
}

@media only screen and (max-width: 480px) {
    ._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 ._pillContainer_152x6_48 {
        border-bottom:unset;
        grid-column: span 4;
        width: 100%
    }
}

._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 ._pillContainer_152x6_48 ._viewMorePills_152x6_70 {
    display: none
}

@media only screen and (max-width: 480px) {
    ._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 ._pillContainer_152x6_48 ._viewMorePills_152x6_70 {
        display:block;
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: .875rem;
        line-height: 145%;
        color: #2c74d6
    }
}

._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 ._pillContainer_152x6_48 ._clearBtn_152x6_84 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: .875rem;
    line-height: 145%;
    color: #2c74d6
}

._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 ._pillContainer_152x6_48 ._clearBtn_152x6_84:hover {
    cursor: pointer
}

._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 ._pills_152x6_21 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: .875rem;
    line-height: 145%;
    color: #5b5e61;
    letter-spacing: .031rem;
    padding: .37rem .62rem;
    border-radius: 1.25rem;
    cursor: pointer;
    border: .06rem solid #e9eef5
}

._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 ._activePill_152x6_108 {
    color: #fff;
    background-color: #2c74d6;
    border: .06rem solid none
}

._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 ._search_152x6_113 ._searchInput_152x6_113 {
    width: 20.5rem;
    height: auto
}

@media only screen and (max-width: 1200px) {
    ._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 ._search_152x6_113 ._searchInput_152x6_113 {
        width:100%
    }
}

@media only screen and (max-width: 1200px) {
    ._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 ._search_152x6_113 {
        width:100%
    }
}

@media only screen and (max-width: 480px) {
    ._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 ._search_152x6_113,._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._pillsAndSearchRow_152x6_21 ._search_152x6_113 ._searchInput_152x6_113 {
        width:100%
    }
}

._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._recentWhitepapers_152x6_135 {
    padding-bottom: 2rem
}

@media only screen and (max-width: 480px) {
    ._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._recentWhitepapers_152x6_135 {
        padding-bottom:4rem
    }
}

._whitepapersPage_152x6_9 ._whitepaperListContainer_152x6_21 ._cardList_152x6_143 {
    padding: 4rem 0
}

._container_152x6_147 {
    grid-column: span 12;
    padding-bottom: 4rem
}

@media only screen and (max-width: 1200px) {
    ._container_152x6_147 {
        grid-column:span 8
    }
}

@media only screen and (max-width: 480px) {
    ._container_152x6_147 {
        grid-column:span 4;
        padding-bottom: 4rem
    }
}

._container_152x6_147 ._heading_152x6_162 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 120%;
    color: #091432;
    letter-spacing: .031rem
}

._container_152x6_147 ._cardsContainer_152x6_171 {
    padding: 1.5rem 0;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 1rem;
    row-gap: 1.5rem
}

@media only screen and (max-width: 1200px) {
    ._container_152x6_147 ._cardsContainer_152x6_171 {
        display:grid;
        grid-template-columns: repeat(2,1fr)
    }
}

@media only screen and (max-width: 480px) {
    ._container_152x6_147 ._cardsContainer_152x6_171 {
        display:grid;
        grid-template-columns: repeat(1,1fr);
        row-gap: 1rem
    }
}

._container_152x6_147 ._buttonContainer_152x6_191 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 0
}

._container_152x6_147 ._buttonContainer_152x6_191 ._viewMore_152x6_70 {
    width: 6.25rem;
    height: 3rem
}
